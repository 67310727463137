.about-container {
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-top: 2vh;
  width: 90%;
}

.card {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  border: #E7414B 2px solid;
  border-radius: 25px;
  padding: 1%;
  background-color: rgba(113,121,126,0.3);
}

.about-header {
  font-weight: bold;
}

.about-container.sm {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card.sm {
  margin: 2.5% auto;
  width: 80%;
  padding: 2.5%;
}
