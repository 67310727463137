div.contact {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5%;
  border: 2px solid #E7414B;
  border-radius: 25px;
}

.title-logo {
  display: flex;
  width: 100%;
  text-align: center;
}

.title-logo > h2 {
  width: 100%;
  margin: auto;
  margin-right: 25%;
  text-wrap: nowrap;
}

.title-logo > img {
  width: 10%;
  margin-left: 17.5%;
}
.contact-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.call-now {
  width: 30%;
  margin: 0% 3%;
  margin-bottom: 3%;
  font-weight: bold;
}

.form {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.form-group > label {
  font-weight: bold;
}

.form-group > input {
  margin-bottom: 1%;
}

.submit {
  width: 25%;
  margin-top: 2%;
  margin-bottom: 3%;
  border: 2px solid #e7414b;
  border-radius: 50px;
  padding: 1%;
  white-space: nowrap;
  width: 33%;
  background-color: #e7414b;
  transition-duration: 0.5s;
  cursor: pointer;
}

.submit:hover {
  background-color: rgba(113,121,126,0.3);
}

/* error message css */

.form-group.error .input,
.form-group.error .react-select__control {
  border-color: hsl(0, 100%, 70%);
  color: hsl(0, 100%, 70%);
  background: hsl(0, 100%, 95%);
}

.form-group.error .contact-label {
  /* color: hsl(0, 100%, 70%); */
  color: black;
}

.form-group.error .msg {
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
  font-size: 0.8em;
  /* color: hsl(0, 100%, 70%); */
  color: black;
}

.contact.sm {
  width: 90%;
}

.contact.sm > .title-logo > img {
  width: 20%;
  margin-left: 12.5%;
}

.contact.sm > .title-logo > h2 {
  margin-right: 10%;
}

.contact.sm > form {
  width: 75%;
  align-items: center;
}

.contact.sm > form > .form-group {
  width: 90%;
}

.contact.sm > form > .form-group > label {
  display: flex;
}

.contact.sm > form > .form-group > input {
  width: 100%;
}
