.equipment-carousel {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: center;
  margin-top: 5vh;
}

ul.thumbs.animated {
  display: none;
}

.carousel-image {
  height: 55vh;
}

.carousel .slider-wrapper {
  background-color: rgba(113, 121, 126, 0.3);
}

.prev-btn,
.next-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  z-index: 1;
  border: none;
  width: 10%;
  height: 10%;
  border-radius: 50%;
  color: white;
  opacity: 0.4;
}

.prev-btn:hover,
.next-btn:hover {
  opacity: 1;
}

.prev-btn {
  left: 0;
}

.next-btn {
  right: 0;
}

.car-arrow {
  width: 100%;
  height: 80%;
}
