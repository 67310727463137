.partnership-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    background-color: rgba(113,121,126,0.3);
    border: 2px solid #e7414b;
    border-radius: 20px;
    padding: 2%;
    margin-bottom: 2%;
}

.partnership-header {
    font-weight: bold;
    margin-bottom: 2%;
}

.partnership-container > ul {
    max-width: 80%;
}

.entry {
    margin: 1%;
}

.entry-header {
    font-weight: bold;
}