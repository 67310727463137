.homepage {
    max-width: 1400px;
    margin: auto;
}

.footer {
    background-color: black;
    color: white;
    position: absolute;
    min-width: 100%;
    text-align: center;
}

.footer-box {
    display: flex;
    justify-content: space-around;
    max-width: 1400px;
    margin: auto;
    align-items: center;
}

.footer-box > div > a {
    color: white;
    padding: 10%;
}

.footer-box > p > a {
    color: white;
}