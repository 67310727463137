div.navbar {
  margin: auto;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: space-between;
  border-bottom: #e7414b 10px solid;
}

.navbar > img {
  width: 10%;
  align-self: center;
}

.hamburger-react {
  margin-bottom: 10%;
}

.navbuttons {
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  border-bottom: none;
  align-self: flex-end;
}

.navbuttons > button {
  border: 2px solid #e7414b;
  border-radius: 50px;
  margin: 3% 1%;
  padding: 3%;
  white-space: nowrap;
  width: 33%;
  background-color: #e7414b;
  transition-duration: 0.5s;
  cursor: pointer;
}

.navbuttons > button:hover {
  background-color: rgba(113,121,126,0.3);
}

.navbar.sm {
  width: 100%;
  background-color: #e7414b;
  position: sticky;
  top: 0;
  border: none;
  z-index: 5;
}

.navbar.sm > img{
  width: 25%;
  height: 25%;
}

.navbuttons.sm {
  flex-direction: column;
  justify-content: center;
  width: 20%;
  align-items: center;
  align-self: flex-start;
  margin-top: 1%;
}

.navbuttons.sm > button {
  border: none;
  width: 90%;
  background-color: white;
}